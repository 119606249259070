

































import BaseMap from '@/components/Map/BaseMap.vue'
import { SiteMarker } from './Marker'
import { LControl } from 'vue2-leaflet'
import Vue from "vue";

const props = {
  site: {
    type: Object,
    default: () => ({
      zoom_level: 16,
      latitude: 0,
      longitude: 0
    })
  },
  name: {
    type: String,
    default: ''
  },
  lockableUpdates: {
    type: Boolean,
    default: true
  }
}

export default Vue.extend({
  name: 'EditSiteMap',
  components: { BaseMap, SiteMarker, LControl },
  props,
  data: () => ({
    locked: true,
    lastZoom: null,
    lastCenter: null,
    last: {}
  }),
  computed: {
    zoom(): number {
      return this.site?.zoom_level || 16
    },
    center(): number[] {
      return [this.site?.latitude || 0, this.site?.longitude || 0]
    }
  },
  methods: {
    update(value: any, type: any): void {
      this.last[type] = value
      if (!this.lockableUpdates || !this.locked) {
        this.$emit(`update${type}`, value)
      } else {
        this.$bus.$emit('SNACKBAR/LOCKED_MAP')
      }
    }
  },
  watch: {
    locked(v: any): void {
      if (!v) {
        Object.keys(this.last).forEach(type =>
          this.$emit(`update${type}`, this.last[type])
        )
      }
    }
  },
  mounted(): void {
    this.$bus.$on('UNLOCK_MAP', () => (this.locked = false))
  },
  beforeDestroy(): void {
    this.$bus.$off('UNLOCK_MAP')
  }
})


























































import { mapActions, mapGetters } from 'vuex'
import AddEditCotsDialog from '@/components/Dialogs/AddEditCots.vue'
import Vue, { PropType } from 'vue'
import { ICots, ICotsDelete } from '@/store/modules/cursor_on_target/types.ts'

const props = {
  setting: {
    type: Object as PropType<ICots>
  },
  site: {
    type: Number
  }
}
export default Vue.extend({
  name: 'CotsContent',
  components: { AddEditCotsDialog },
  props,
  data() {
    return {
      deleteDialog: false
    }
  },
  computed: {
    ...mapGetters('users', ['isAuthorized']),
    cotsEnabled() {
      return this.setting.enabled
    }
  },
  methods: {
    ...mapActions('cursor_on_target', [
      'DELETE_CURSOR_ON_TARGET',
      'UPDATE_CURSOR_ON_TARGET'
    ]),
    async onToggleButtonClicked() {
      if (this.setting) {
        let cots: ICots
        cots = Object.assign({}, this.setting)
        cots.enabled = !this.setting.enabled
        await this.UPDATE_CURSOR_ON_TARGET(cots)
      }
    },
    onDeleteButtonClicked() {
      this.deleteDialog = true
    },
    onCancelDelete() {
      this.deleteDialog = false
    },
    async onCotsDelete() {
      const deleteCotsData: ICotsDelete = {
        site_id: this.site,
        cots_id: this.setting.id
      }
      await this.DELETE_CURSOR_ON_TARGET(deleteCotsData)
    }
  }
})























































































































































import { FormMixin } from '@/components/Mixins'
import Vue, { PropType } from 'vue'
import { ICots, ICotsValidate } from '@/store/modules/cursor_on_target/types.ts'
import { AUTO_JAM_AND_ZONE_TRIGGER_PROBABILITIES } from '@/store/modules/sites/types'
import { ruleMaxValue, ruleMinValue } from '@/utils/rules'
import {isNullOrUndefined, objectsAreEqual} from '@/utils/utils'

const props = {
  siteData: {},
  cots: {
    type: Object as PropType<ICots>,
    default: () => ({
      enabled: true,
      minimum_probability: 0.6,
      rate_limit: 3,
      field_of_view: 0,
      event_version: 3.0,
      protocol: 'udp',
      symbol_type: 'a-s-A-M-H-Q',
      how: 'm-g',
      ip: null,
      port: null
    })
  }
}

export default Vue.extend({
  name: 'CotsForm',
  props,
  mixins: [FormMixin],
  data() {
    return {
      valid: false,
      cotsClone: null,
      rateLimitMin: 0,
      rateLimitMax: 100,
      lat: 0,
      lng: 0,
      cotProtocols: [
        {
          value: 'udp',
          text: 'UDP'
        },
        {
          value: 'tcp',
          text: 'TCP'
        }
      ],
      dialog: false,
      saveClicked: false
    }
  },
  mounted() {
    this.cotsClone = { ...this.cots }
  },
  computed: {
    hasChanged() {
      return !objectsAreEqual(this.cots, this.cotsClone)
    },
    minimumProbability: {
      get() {
        if (isNullOrUndefined(this.cotsClone?.minimum_probability)) return null
        return this.cotsClone?.minimum_probability
      },
      set(val) {
        this.cotsClone['minimum_probability'] = val
      }
    },
    rateLimit: {
      get() {
        if (isNullOrUndefined(this.cotsClone?.rate_limit)) return null
        return this.cotsClone?.rate_limit
      },
      set(val) {
        this.cotsClone['rate_limit'] = val
      }
    },
    symbolType: {
      get() {
        return this.cotsClone?.symbol_type || null
      },
      set(val) {
        this.cotsClone['symbol_type'] = val
      }
    },
    how: {
      get() {
        return this.cotsClone?.how || null
      },
      set(val) {
        this.cotsClone['how'] = val
      }
    },
    fieldOfView: {
      get() {
        return this.cotsClone?.field_of_view || null
      },
      set(val) {
        this.cotsClone['field_of_view'] = val
      }
    },
    eventVersion: {
      get() {
        return this.cotsClone?.event_version || null
      },
      set(val) {
        this.cotsClone['event_version'] = val
      }
    },
    protocol: {
      get() {
        return this.cotsClone?.protocol || null
      },
      set(val) {
        this.cotsClone['protocol'] = val
      }
    },
    ipAddress: {
      get() {
        return this.cotsClone?.ip || null
      },
      set(val) {
        if (!val) return
        this.cotsClone['ip'] = val
      }
    },
    port: {
      get() {
        return this.cotsClone?.port || null
      },
      set(val) {
        this.cotsClone['port'] = val
      }
    },
    allProbabilities() {
      return AUTO_JAM_AND_ZONE_TRIGGER_PROBABILITIES
    },
    cotsIpAddressRules() {
      return [this.rules.required, this.rules.ipAddress]
    }
  },
  methods: {
    ruleMaxValue,
    ruleMinValue,
    ruleRequiredMinProb(v) {
      if (isNullOrUndefined(v)) return 'Minimum Probability Required'
      return AUTO_JAM_AND_ZONE_TRIGGER_PROBABILITIES.map((item) => item.value).includes(v) || 'Invalid Minimum Probability'
    },
    ruleRequiredProtocol(v) {
      if (isNullOrUndefined(v)) return 'Protocol Required'
      return ['udp', 'tcp'].includes(v) || 'Invalid Protocol'
    },
    onCancel() {
      this.$refs.form.resetValidation()
      this.$refs.form.reset()
      this.$emit('cancel')
    },
    onSubmit() {
      this.$emit('submit', this.cotsClone)
    }
  }
})

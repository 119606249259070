























import CotsContent from '@/components/Forms/Sites/CotsContent.vue'
import AddEditCotsDialog from '@/components/Dialogs/AddEditCots.vue'
import Vue, { PropType } from 'vue'
import { ICots, ICotsList } from '@/store/modules/cursor_on_target/types'

const props = {
  cotsData: {
    type: Object as PropType<ICotsList>,
    default: () => ({})
  },
  siteData: {
    type: Object
  },
  title: {
    type: String
  }
}

export default Vue.extend({
  name: 'CotsGroupCard',
  props,
  components: {
    AddEditCotsDialog,
    CotsContent
  },
  computed: {
    cotsItems(): ICots[] {
      return this.cotsData.cots
    }
  }
})


















































































































































































































































































































































































































































































































































































































import SubHeader from '@/components/Forms/SubHeader.vue'
import { mapActions, mapGetters, mapState } from 'vuex'
import { FormMixin } from '@/components/Mixins'
import CotsGroupCard from '@/components/Forms/Sites/CotsGroupCard.vue'
import { getTheme, getThemesList } from '@/components/Map/utils/themes'
import {
  AUTO_JAM_AND_ZONE_TRIGGER_PROBABILITIES,
  ERoundFrequencyBand,
  ESensitivity,
  ESiteMode,
  ISite,
  MAX_BIRTH_MODEL_HEIGHT,
  MIN_BIRTH_MODEL_HEIGHT
} from '@/store/modules/sites/types'
import utils from '@/components/Map/utils'
import Vue, { PropType } from 'vue'
import {
  convertUnitIfImperial,
  convertValIfImperial
} from '@/store/utils/index'
import { ruleMaxValue, ruleMinValue, ruleRequired } from '@/utils/rules'

// @ts-ignore
import { isNullOrUndefined, latLngToMgrs, mgrsToLatLng } from '@/utils/utils.js'
import DRangeSlider from '@/components/Base/DRangeSlider.vue'

const props = {
  siteData: {
    type: Object as PropType<ISite>,
    default: () => ({
      name: '',
      zoom_level: 13,
      latitude: 0,
      longitude: 0
    })
  },
  newSite: {
    type: Boolean,
    default: false
  }
}

const MIN_ZOOM = 1
const MAX_ZOOM = 18

const MIN_SPEEDLEADER_ESTIMATION_TIME = 0
const MAX_SPEEDLEADER_ESTIMATION_TIME = 30

export default Vue.extend({
  name: 'SiteForm',
  mixins: [FormMixin],
  components: {
    SubHeader,
    CotsGroupCard,
    DRangeSlider
  },
  props,
  data: () => ({
    minZoom: MIN_ZOOM,
    maxZoom: MAX_ZOOM,
    minSpeedleaderEstimationTime: MIN_SPEEDLEADER_ESTIMATION_TIME,
    maxSpeedleaderEstimationTime: MAX_SPEEDLEADER_ESTIMATION_TIME,
    mgrsMask: 'NNNNN NNNNN NNNNN',
    isMounted: false,
    defaultSite: false,
    siteModes: [
      { value: ESiteMode.Operational, text: 'Operational' },
      { value: ESiteMode.Monitor, text: 'Monitor' },
      { value: ESiteMode.Calibration, text: 'Calibration' }
    ],
    sensitivities: [
      { value: ESensitivity.Normal, text: 'Normal' },
      { value: ESensitivity.High, text: 'High' }
    ],
    roundFrequencyBands: [
      { value: ERoundFrequencyBand.None, text: 'None' },
      { value: ERoundFrequencyBand.Controller, text: 'Controller' },
      { value: ERoundFrequencyBand.All, text: 'All' }
    ],
    validMgrs: true
  }),
  computed: {
    ...mapGetters('system', ['systemSetting']),
    ...mapState(['timezonesList']),
    ...mapState('users', ['clientsList', 'user']),
    ...mapGetters('users', ['isAuthorized']),
    ...mapState(['mgrsEnabled']),
    ...mapState('sentries', ['sentriesSet']),
    ...mapGetters('cursor_on_target', { cotsData: 'settings' }),
    hasSiteId() {
      return !isNullOrUndefined(this.siteData?.id || null)
    },
    systemWarningsEnabledModel: {
      get() {
        return this.siteData?.system_warning_enabled || false
      },
      set(val) {
        this.siteData.system_warning_enabled = val
      }
    },
    showWhitelistedDetectionsModel: {
      get() {
        return this.siteData?.show_whitelisted_detections || false
      },
      set(val) {
        this.siteData.show_whitelisted_detections = val
      }
    },
    minDisplayProbabilityModel: {
      get() {
        return this.siteData?.minimum_display_probability || 0
      },
      set(val) {
        this.siteData.minimum_display_probability = val
      }
    },
    autoJamIntervalModel: {
      get() {
        return this.siteData?.auto_jam_interval || null
      },
      set(val) {
        this.siteData.auto_jam_interval = val
      }
    },
    autoDisruptDetectionCountModel: {
      get() {
        return this.siteData?.auto_disruption_detection_count || null
      },
      set(val) {
        this.siteData.auto_disruption_detection_count = val
      }
    },
    autoJamModel: {
      get() {
        return this.siteData?.auto_jam || false
      },
      set(val) {
        this.siteData.auto_jam = val
      }
    },
    siteModeModel: {
      get() {
        return this.siteData?.mode || null
      },
      set(val) {
        this.siteData.mode = val
      }
    },
    acceptLocationUpdatesModel: {
      get() {
        return this.siteData?.accept_location_updates || false
      },
      set(val) {
        this.siteData['accept_location_updates'] = val
        if (val === false) {
          this.siteData['locator_id'] = null
          delete this.siteData.Locator
          this.locator_id = null
          this.siteData.map_center_latitude = null
          this.siteData.map_center_longitude = null
        }
      }
    },
    clientIdModel: {
      get() {
        return this.siteData?.client_id || ''
      },
      set(val) {
        this.siteData['client_id'] = val ? val : null
      }
    },
    timezoneModel: {
      get() {
        return this.siteData?.timezone || 'UTC'
      },
      set(val) {
        this.siteData['timezone'] = val
      }
    },
    siteNameModel: {
      get() {
        return this.siteData?.name || null
      },
      set(val) {
        this.siteData['name'] = val
      }
    },
    allProbabilities() {
      return AUTO_JAM_AND_ZONE_TRIGGER_PROBABILITIES
    },
    speedleaderEstimationTime: {
      get() {
        return this.siteData?.speedleader_estimation_time
      },
      set(time: number) {
        if (time < this.minSpeedleaderEstimationTime) {
          time = this.minSpeedleaderEstimationTime
        }
        if (time > this.maxSpeedleaderEstimationTime) {
          time = this.maxSpeedleaderEstimationTime
        }
        this.siteData['speedleader_estimation_time'] = time
      }
    },
    zoomLevel: {
      get(): number {
        return this.siteData?.zoom_level || 13
      },
      set(zoom: number) {
        if (zoom < this.minZoom) {
          zoom = this.minZoom
        }
        if (zoom > this.maxZoom) {
          zoom = this.maxZoom
        }
        this.siteData['zoom_level'] = zoom
      }
    },
    mapCenterModel: {
      get(): string {
        if (this.siteData === null) return 'site'
        return this.siteData.map_center_latitude === null &&
          this.siteData.map_center_longitude === null
          ? 'site'
          : 'custom'
      },
      set(val: string) {
        if (val == 'site') {
          this.siteData.map_center_latitude = null
          this.siteData.map_center_longitude = null
        } else {
          this.siteData.map_center_latitude = this.processedLatitude
          this.siteData.map_center_longitude = this.processedLongitude
        }
      }
    },
    processedLongitude: {
      get(): number {
        return utils.normaliseLongitude(this.siteData?.longitude || 0)
      },
      set(val: any) {
        this.siteData['longitude'] = utils.normaliseLongitude(val)
      }
    },
    processedLatitude: {
      get(): number {
        return utils.normaliseLatitude(this.siteData?.latitude || 0)
      },
      set(val: any) {
        this.siteData['latitude'] = utils.normaliseLatitude(val)
      }
    },
    processedMapCenterLongitude: {
      get(): number {
        return utils.normaliseLongitude(
          this.siteData?.map_center_longitude || 0
        )
      },
      set(val: any) {
        this.siteData['map_center_longitude'] = utils.normaliseLongitude(val)
      }
    },
    processedMapCenterLatitude: {
      get(): number {
        return utils.normaliseLatitude(this.siteData?.map_center_latitude || 0)
      },
      set(val: any) {
        this.siteData['map_center_latitude'] = utils.normaliseLatitude(val)
      }
    },
    themeList(): any {
      return getThemesList()
    },
    sysTheme: {
      get(): string {
        return this.$store.state.theme
      },
      set(theme: string) {
        const themeStyle = getTheme(theme)
        this.$store.dispatch('SET_THEME', { name: theme })
        if (themeStyle.forceMap) {
          this.$store.dispatch('maps/setMapLayer', themeStyle.map)
        }
        this.$emit('update:theme', theme)
      }
    },
    isDefaultSite: {
      get(): boolean {
        return this.user.default_site_id === this.siteData?.id || false
      },
      async set(state: any) {
        if (state) {
          this.$emit('update:defaultSite', this.siteData.id || true)
        } else {
          this.$emit('update:defaultSite', null)
        }
      }
    },
    mgrsLocation: {
      get() {
        return latLngToMgrs(this.siteData.latitude, this.siteData.longitude)
      },
      set(val) {
        try {
          const [long, lat] = mgrsToLatLng(val)
          this.validMgrs = true
          this.siteData.latitude = lat
          this.siteData.longitude = long
          this.$bus.$emit(
            'mapCenter',
            'addSiteMap',
            [lat, long],
            this.zoomLevel
          )
        } catch {
          this.validMgrs = false
        }
      }
    },
    sentriesList(): any {
      return Object.values(this.sentriesSet)
    },
    locatorsItems(): any {
      return this.sentriesList.map(sentry => ({
        id: sentry.id,
        name: sentry.name
      }))
    },
    locator: {
      get(): any {
        return this.sentriesList.find(
          sentry => sentry.id === this.siteData.locator_id
        )
      },
      set(v: any) {
        this.siteData.locator_id = this.sentriesList.find(
          sentry => v === sentry.name
        )?.id
      }
    },
    parsedClientsList(): any {
      return this.clientsList.map(c => {
        return {
          id: c.id,
          name: c.name
        }
      })
    },
    minimum_display_probability: {
      get(): number {
        return this.siteData['minimum_display_probability'] || 0
      },
      set(prob: number) {
        this.siteData['minimum_display_probability'] = prob
      }
    },
    auto_disruption_probability: {
      get(): number {
        return this.siteData['auto_disruption_probability'] || 0
      },
      set(prob: number) {
        this.siteData['auto_disruption_probability'] = prob
      }
    },
    alwaysShowRfBeamModel: {
      get(): boolean {
        return this.siteData?.always_show_rf_beam || false
      },
      set(val: boolean) {
        this.siteData['always_show_rf_beam'] = val
      }
    },
    sensitivityModel: {
      get(): ESensitivity {
        return this.siteData?.sensitivity || ESensitivity.Normal
      },
      set(val: ESensitivity) {
        this.siteData['sensitivity'] = val
      }
    },
    maxLocationVarianceModel: {
      get(): number {
        return this.convertValIfImperial(
          this.systemSetting,
          this.siteData?.max_location_variance || 250,
          'm'
        )
      },
      set(val: number) {
        this.siteData['max_location_variance'] = this.convertValIfImperial(
          this.systemSetting,
          val,
          'ft'
        )
      }
    },
    roundFrequencyBandModel: {
      get(): ERoundFrequencyBand {
        return (
          this.siteData?.round_frequency_band || ERoundFrequencyBand.Controller
        )
      },
      set(val: ERoundFrequencyBand) {
        this.siteData['round_frequency_band'] = val
      }
    },
    probUavFilterModel: {
      get(): boolean {
        return this.siteData?.prob_uav_filter || false
      },
      set(val: boolean) {
        this.siteData.prob_uav_filter = val
      }
    },
    maxBirthModelHeight() {
      return MAX_BIRTH_MODEL_HEIGHT
    },
    minBirthModelHeight() {
      return MIN_BIRTH_MODEL_HEIGHT
    },
    birthModelHeightRange: {
      get(): number[] {
        return [
          this.convertValIfImperial(
            this.systemSetting,
            this.siteData?.birth_model_height_min || -50,
            'm'
          ),
          this.convertValIfImperial(
            this.systemSetting,
            this.siteData?.birth_model_height_max || 150,
            'm'
          )
        ]
      },
      set(val: number[]) {
        this.siteData.birth_model_height_min = this.convertValIfImperial(
          this.systemSetting,
          val[0],
          'ft'
        )
        this.siteData.birth_model_height_max = this.convertValIfImperial(
          this.systemSetting,
          val[1],
          'ft'
        )
      }
    }
  },
  async mounted() {
    await this.$store.dispatch('users/FETCH_CLIENT')
  },
  methods: {
    ruleRequired,
    ruleMinValue,
    ruleMaxValue,
    ...mapActions('system', { setTheme: 'SET_THEME' }),
    convertValIfImperial: convertValIfImperial,
    convertUnitIfImperial: convertUnitIfImperial,

    zoomTextChanged(zoom): void {
      if (zoom) {
        this.zoomLevel = zoom
      } else {
        this.zoomLevel = 5
      }
    },
    validateForm(payload): void {
      this.$emit('validate', payload)
    },
    onEnterKeyup(event): void {
      if (event.keyCode === 13) this.$emit('onEnter')
    },
    checkLocatorValid(val) {
      if (typeof val === 'boolean') {
        // Set first locator from locators array as default value
        if (this.locatorsItems.length) {
          this.siteData.locator_id = this.locatorsItems[0]?.id
        }
        if (val === true && !this.locator) this.validateForm(false)
        if (!val) this.validateForm(true)
      }
    }
  },
  watch: {
    user: {
      handler: function() {
        this.$emit('update:defaultSite', this.user.default_site_id)
      },
      immediate: true
    }
  }
})


























































import { mapActions, mapGetters, mapState } from 'vuex'
import { DialogMixin } from '@/components/Mixins'
import schema from '@/components/Forms/Sites/schema.js'

import EditSiteMap from '@/components/Map/EditSite.vue'
import SiteForm from '@/components/Forms/Sites/SiteForm.vue'
import utils from '@/components/Map/utils'
import Vue, { PropType } from 'vue'
import { convertValIfImperial } from '@/store/utils/index'

import {
  IData,
  ISite,
  ISchemaObj,
  ESiteMode
} from '@/store/modules/sites/types'

const props = {
  siteData: {
    type: Object as PropType<ISite>,
    default: () => ({})
  },
  dialog: {
    type: Boolean,
    default: false
  }
}

export default Vue.extend({
  name: 'add-site',
  components: { EditSiteMap, SiteForm },
  mixins: [DialogMixin],
  props,
  data: (): IData => ({
    valid: true,
    name: 'addSiteMap',
    defaultSite: null,
    siteDataClone: null
  }),
  computed: {
    ...mapState('sites', ['newSiteOrigin']),
    ...mapGetters('system', ['systemSetting']),
    ...mapGetters('sites', ['status', 'error', 'activeSiteId']),
    ...mapState('users', ['user']),
    sensorFields(): Array<ISchemaObj> {
      return schema.map(f => ({
        ...f,
        value: f.value
      }))
    }
  },
  methods: {
    ...mapActions('sites', [
      'CREATE_SITE',
      'FETCH_SITES',
      'CLEAR_STATUS',
      'getNewSiteOrigin'
    ]),
    ...mapActions('sentries', {
      fetchSentries: 'FETCH_SENTRIES',
    }),
    ...mapActions('users', ['UPDATE_USER']),

    convertValIfImperial: convertValIfImperial,

    updateCoord({ lat, lng }): boolean {
      return (
        ['latitude', 'longitude'].includes(
          document.activeElement.getAttribute('name')
        ) ||
        Object.assign(this.siteDataClone, { latitude: lat, longitude: lng })
      )
    },
    updateZoom(zoom): ISite {
      if (!this.siteDataClone) return
      return Object.assign(this.siteDataClone, { zoom_level: zoom })
    },
    validateForm(v): void {
      this.valid = v
    },
    async saveSite() {
      const res = this.sensorFields.reduce((acc, { key, type }) => {
        acc[key] =
          type === Boolean ? !!this.siteDataClone[key] : this.siteDataClone[key]
        return acc
      }, {})

      // keep latitude and longitude sane
      res.longitude = utils.normaliseLongitude(res.longitude)
      res.latitude = utils.normaliseLatitude(res.latitude)

      // Handle map center coordinates from local component state
      if (this.siteDataClone?.map_center_latitude && this.siteDataClone?.map_center_longitude) {
        res.map_center_latitude = utils.normaliseLatitude(this.siteDataClone?.map_center_latitude)
        res.map_center_longitude = utils.normaliseLongitude(this.siteDataClone?.map_center_longitude)
      }

      Object.keys(res).forEach(k => {
        if (!res[k] && res[k] !== false) {
          delete res[k]
        }
      })

      const site = await this.CREATE_SITE(res)

      if (this.status === 'FAILED') {
        this.$bus.$emit('APP_MESSAGE', {
          flush: 'manual',
          color: 'error',
          title: 'Error Creating Site',
          message: `Reason: ${this.error}`
        })
        return
      }

      // set default site
      const newProfile = Object.assign({}, this.user)
      if (this.defaultSite) {
        newProfile.default_site_id = site.id
      }

      let promiseList = [this.UPDATE_USER(newProfile), this.fetchSentries()]

      await Promise.all(promiseList)
      if (this.status === 'OK') {
        await this.FETCH_SITES()
        return setTimeout(() => this.$router.push('/installations'), 500)
      } else {
        this.$bus.$emit('APP_MESSAGE', {
          flush: 'manual',
          color: 'error',
          title: 'Error Creating Site',
          message: `Reason: ${this.error}`
        })
      }
    }
  },
  async mounted(): Promise<void> {
    // prevent bug with leaflet not rendering all tiles
    this.$refs.addForm.scrollTop = 0
    this.$_dismiss()

    await this.getNewSiteOrigin()

    this.siteDataClone = { ...this.siteData }

    this.CLEAR_STATUS()

    let {
      latitude = 0,
      longitude = 0,
      mode = ESiteMode.Monitor,
      timezone = 'UTC',
      zoom_level = 13,
      map_center_latitude = null,
      map_center_longitude = null,
      speedleader_estimation_time = 10
    } = this.site || this.newSiteOrigin

    this.center = [latitude, longitude]
    this.siteDataClone = Object.assign(
      schema.reduce((acc, curr) => {
        acc[curr.key] = curr.value || null
        return acc
      }, {}),
      { latitude, longitude, mode, timezone, zoom_level, map_center_latitude, map_center_longitude, speedleader_estimation_time }
    )
  }
})

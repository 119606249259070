import { EShowRadarDetectionType } from '@/store/modules/sites/types'

export default [
  {
    label: 'Name',
    required: true,
    key: 'name',
    fieldType: 'v-text-field'
  },
  {
    label: 'Address',
    key: 'address',
    fieldType: 'v-text-field'
  },
  {
    label: 'City',
    key: 'city',
    fieldType: 'v-text-field'
  },
  {
    label: 'State',
    key: 'state',
    fieldType: 'v-text-field'
  },
  {
    label: 'Zip',
    key: 'zip',
    fieldType: 'v-text-field'
  },
  {
    label: 'Latitude',
    required: true,
    key: 'latitude',
    fieldType: 'v-text-field'
  },
  {
    label: 'Longitude',
    required: true,
    key: 'longitude',
    fieldType: 'v-text-field'
  },
  {
    label: 'Time Zone',
    value: 'Etc/UTC',
    key: 'timezone',
    fieldType: 'v-text-field'
  },
  {
    label: 'Zoom',
    value: 5,
    key: 'zoom_level',
    fieldType: 'v-text-field'
  },
  {
    label: 'Alert Suppression Time Window (s)',
    key: 'alert_suppression_time_window',
    fieldType: 'v-text-field',
    value: 600
  },
  {
    label: 'Client',
    value: null,
    key: 'client_id',
    fieldType: 'v-select'
  },
  {
    label: 'Auto Jamming Interval',
    key: 'auto_jam_interval',
    fieldType: 'v-text-field',
    value: 60
  },
  {
    label: 'Show Whitelisted Detections',
    value: true,
    key: 'show_whitelisted_detections',
    fieldType: 'v-checkbox'
  },
  {
    label: 'Offline Sensors Alerts',
    value: false,
    key: 'trigger_sensors_offline_alerts',
    fieldType: 'v-checkbox'
  },
  {
    label: 'System Warnings',
    value: true,
    key: 'system_warning_enabled',
    fieldType: 'v-checkbox'
  },
  {
    label: 'Accept Location Updates',
    value: false,
    key: 'accept_location_updates',
    fieldType: 'v-checkbox'
  },
  {
    label: 'Accept Location Updates',
    value: false,
    key: 'auto_jam',
    fieldType: 'v-swtich'
  },
  {
    label: 'Locator',
    value: null,
    key: 'locator_id',
    fieldType: 'v-select'
  },
  {
    label: 'Always Show RF Sector',
    value: false,
    key: 'always_show_rf_beam',
    fieldType: 'v-checkbox'
  },
  {
    label: 'Operation Mode',
    value: 'monitor',
    key: 'mode',
    fieldType: 'v-select'
  },
  {
    label: 'Sensitivity',
    value: 'normal',
    key: 'sensitivity',
    fieldType: 'v-select'
  },
  {
    label: 'Max Location Variance',
    value: 250,
    key: 'max_location_variance',
    fieldType: 'd-param-slider'
  },
  {
    label: 'Combine Frequency Bands',
    value: 'controller',
    key: 'round_frequency_band',
    fieldType: 'v-select'
  },
  {
    label: 'Probability UAV Filter',
    value: false,
    key: 'prob_uav_filter',
    fieldType: 'v-select'
  },
  {
    label: 'New Detection Height Range Min',
    value: -10,
    key: 'birth_model_height_min',
    fieldType: 'v-select'
  },
  {
    label: 'New Detection Height Range Max',
    value: 120,
    key: 'birth_model_height_max',
    fieldType: 'v-select'
  }
]



















































































import { isEqual } from 'lodash'
import { mapActions, mapGetters, mapState } from 'vuex'
import { DialogMixin } from '@/components/Mixins'
import schema from '@/components/Forms/Sites/schema.js'

import EditSiteMap from '@/components/Map/EditSite.vue'
import SiteForm from '@/components/Forms/Sites/SiteForm.vue'
import Vue, { PropType } from 'vue'
import { convertValIfImperial } from '@/store/utils/index'

import { IDataEdit, ISite, ISchemaObj } from '@/store/modules/sites/types'

const props = {
  id: [String, Number],
  dialog: {
    type: Boolean,
    default: false
  }
}

export default Vue.extend({
  name: 'edit-site',
  components: { EditSiteMap, SiteForm },
  mixins: [DialogMixin],
  props,
  data: (): IDataEdit => ({
    defaultSite: null,
    valid: true,
    name: 'addSiteMap',
    deleteDialog: false,
    selectedTheme: null,
    siteData: null,
    siteResetData: null
  }),
  computed: {
    ...mapGetters('system', ['systemSetting']),
    ...mapGetters('sites', [
      'status',
      'error',
      'activeSiteId',
      'sitesList',
      'sitesSet',
      'activeSite'
    ]),
    ...mapGetters('users', ['isAuthorized']),
    ...mapGetters(['getRadiatingCircle']),
    ...mapState('users', ['user']),
    sensorFields(): Array<ISchemaObj> {
      return schema.map(f => ({
        ...f,
        value: f.value
      }))
    }
  },
  methods: {
    ...mapActions('sites', [
      'UPDATE_SITE',
      'DELETE_SITE',
      'CLEAR_STATUS',
      'FETCH_SITES'
    ]),
    ...mapActions('cursor_on_target', ['FETCH_CURSOR_ON_TARGET']),
    ...mapActions('users', ['UPDATE_USER', 'SET_THEME_NAME']),
    ...mapActions(['setRadiatingCircle']),

    convertValIfImperial: convertValIfImperial,

    updateCoord({ lat, lng }): boolean {
      return (
        ['latitude', 'longitude'].includes(
          document.activeElement.getAttribute('name')
        ) || Object.assign(this.siteData, { latitude: lat, longitude: lng })
      )
    },
    updateZoom(zoom): ISite {
      return Object.assign(this.siteData, { zoom_level: zoom })
    },
    validateForm(v): void {
      this.valid = v
    },
    async saveSite() {
      if (this.valid) {
        const res = this.siteData

        res['client_id'] = !!res['client_id'] ? res['client_id'] : 0

        // set default site
        const newProfile = Object.assign({}, this.user)
        if (this.user.default_site_id !== this.defaultSite) {
          newProfile.default_site_id = this.defaultSite
        }

        if (!this.siteData.accept_location_updates) {
          res['locator_id'] = 0
        }

        // Update concentric marker coordinates when changing site location
        {
          const concentricMarkerCoordinates = this.getRadiatingCircle?.coordinates
          const siteCoordinates = [res.latitude, res.longitude]
          if (
            !!concentricMarkerCoordinates &&
            !!concentricMarkerCoordinates?.length &&
            !isEqual(siteCoordinates, concentricMarkerCoordinates)
          ) {
            const updatedConcentricMarker = {
              ...this.getRadiatingCircle,
              coordinates: siteCoordinates
            }
            let updatedUser = {...this.user}
            updatedUser.settings.zoneSettings.radiatingCircle = updatedConcentricMarker;

            this.setRadiatingCircle(updatedConcentricMarker)
            await this.UPDATE_USER(updatedUser)
          }
        }

        let promiseList = [
          this.UPDATE_SITE({ ...res, id: this.id }),
          this.UPDATE_USER(newProfile)
        ]

        if (this.selectedTheme) {
          promiseList.push(
            this.SET_THEME_NAME({
              user_id: this.user.id,
              theme: this.selectedTheme
            })
          )
        }

        await Promise.all(promiseList)
        if (this.status === 'OK') {
          return setTimeout(() => this.$router.push('/installations'), 500)
        } else {
          this.$bus.$emit('APP_MESSAGE', {
            flush: 'manual',
            color: 'error',
            title: 'Error Updating Site',
            message: `Reason: ${this.error}`
          })
        }
      }
    },
    clickDeleteButton(): void {
      this.deleteDialog = true
    },
    onCancelDelete(): void {
      this.deleteDialog = false
    },
    async onDeleteSite() {
      await this.DELETE_SITE(this.activeSite)
      this.deleteDialog = false
      if (this.status === 'OK') {
        await this.FETCH_SITES()
        return setTimeout(() => this.$router.push({ name: 'DataLoader' }), 500)
      } else {
        this.$bus.$emit('APP_MESSAGE', {
          flush: 'manual',
          color: 'error',
          title: 'Error Deleting Site',
          message: `Reason: ${this.error}`
        })
      }
    }
  },
  created(): void {
    this.CLEAR_STATUS()
    this.FETCH_CURSOR_ON_TARGET(this.id)
    this.siteData = { ...this.sitesSet[this.id] } //deep clone to avoid mutating state directly
    this.siteResetData = Object.assign({}, this.siteData)
  }
})

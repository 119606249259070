





























































import { mapActions, mapGetters } from 'vuex'
import { DialogMixin, FormMixin } from '@/components/Mixins'
import CotsForm from '@/components/Forms/Sites/CotsForm.vue'
import Vue, { PropType } from 'vue'
import {
  ICots,
  ICotsCreate,
  ICotsValidate
} from '@/store/modules/cursor_on_target/types.ts'

const props = {
  cots: {
    type: Object as PropType<ICots>
  },
  title: {
    type: String,
    default: ''
  }
}

export default Vue.extend({
  name: 'AddEditCotsDialog',
  components: {
    CotsForm
  },
  mixins: [DialogMixin, FormMixin],
  props,
  data() {
    return {
      lat: 0,
      lng: 0,
      cotsData: {} as ICots,
      cotProtocols: [
        {
          value: 'udp',
          text: 'UDP'
        },
        {
          value: 'tcp',
          text: 'TCP'
        }
      ],
      valid: false,
      dialog: false,
      saveClicked: false
    }
  },
  mounted() {
    this.initDialog()
  },
  computed: {
    ...mapGetters('sites', { site: 'activeSite' }),
    ...mapGetters('cursor_on_target', ['status', 'error']),
    sentry() {
      return { latitude: this.lat, longitude: this.lng, direction: this.dir }
    }
  },
  methods: {
    ...mapActions('cursor_on_target', [
      'CREATE_CURSOR_ON_TARGET',
      'UPDATE_CURSOR_ON_TARGET'
    ]),
    async onSubmit(payload) {
      this.saveClicked = true
      if (!payload.id) {
        // add new cots
        payload.site_id = this.site.id
        const createCotsData: ICotsCreate = {
          site_id: this.site.id,
          cot: payload
        }
        await this.CREATE_CURSOR_ON_TARGET(createCotsData)
      } else {
        // edit cots
        await this.UPDATE_CURSOR_ON_TARGET(payload)
      }
      this.dialog = this.status !== 'OK' // keep dialog up if not OK
    },
    onMapCenter() {
      const { latitude, longitude } = this.site
      return this.updateCoordinates([latitude, longitude])
    },
    updateCoordinates([lat, lng]) {
      this.lat = lat
      this.lng = lng
    },
    cancel() {
      this.initDialog()
      this.dialog = false
    },
    initDialog() {
      this.$store.commit('cursor_on_target/SET_STATUS', '')
      this.$store.commit('cursor_on_target/SET_ERROR', '')
      this.saveClicked = false
      if (this.cots) {
        Object.assign(this.cots, this.cots)
      }
    }
  },
  watch: {
    dialog(v) {
      if (v) {
        this.initDialog()
      }
    }
  }
})
